var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "flex-column",
    },
    [
      _c(
        "div",
        { staticClass: "filter-container" },
        [
          _c("base-search", {
            staticStyle: { display: "inline-block" },
            attrs: {
              searchList: _vm.$sjglSbsjSearch,
              listQuery: _vm.listQuery,
              dynamic: true,
              noOperation: true,
            },
            on: { search: _vm.handleFilter, "btn-event": _vm.onBtnClicked },
          }),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "bg-white",
          staticStyle: { height: "100%" },
          attrs: { id: "table_box_height" },
        },
        [
          _c("basic-table", {
            attrs: {
              tableHeight: _vm.tableHeight,
              tableHeaderList: _vm.firstHeaderList,
              tableData: _vm.tableData,
              listQuery: _vm.listQuery,
              tableTotal: _vm.tableTotal,
              hasSelection: true,
              isCbxx: true,
            },
            on: {
              pagination: _vm.handleCurrentChange,
              tableRowClick: _vm.tableRowClick,
              handleSelectionChange: _vm.handleSelectionChange,
            },
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }