<template>
  <div class="flex-column" v-loading="loading">
    <div class="filter-container">
      <base-search :searchList="$sjglSbsjSearch" :listQuery='listQuery' v-on:search='handleFilter' v-on:btn-event="onBtnClicked" style="display: inline-block;" :dynamic='true'  :noOperation="true"></base-search>
    </div>
    <div class="bg-white" style="height: 100%;" id="table_box_height">
      <!-- 列表页 -->
      <basic-table :tableHeight="tableHeight" :tableHeaderList="firstHeaderList" :tableData="tableData" :listQuery="listQuery" @pagination="handleCurrentChange" @tableRowClick="tableRowClick" :tableTotal="tableTotal" :hasSelection="true" @handleSelectionChange="handleSelectionChange" :isCbxx="true" ></basic-table >
    </div>
  </div>
</template>

<script>
  import * as basicDataApi from '@/api/basicDataApi/basicDataApi'//基础资料接口
  
  export default {
    name: 'kzgl',//控制管理
    components: {
      
    },
    props: {
      //设备类型
      // deviceType:[String, Object, Array],
      visible:{
        default:false
      },
      //是否是详情，详情进入不可修改
      isCheckDetail:{
        default:false
      },
    },
    directives: {
      
    },
    data() {
      return {
        loading:false,//遮罩
        listQuery: { // 查询条件
          page: 1,
          limit: 50,
          TreeIds:[],//选中集合
          queryType: '0',
          queryKey:'UserNumber',
          queryValue:'',
          OtherKey:'',//查询条件
          OtherValue:'',//查询内容
          issueType:'0',//操作范围
          ids:[],//操作范围传值
          timer:'',
          beginTime:'',
          endTime:'',
          repeat:'',
        },
        // firstHeaderList:[],// 主列表列定义
        firstHeaderList:[// 主列表列定义
          {
            key :  "ssll",
            comment :  "瞬时流量",
            description :  "瞬时流量",
          },
          {
            key :  "ljsl",
            comment :  "累计水量",
            description :  "累计水量",
          },
          {
            key :  "bj",
            comment :  "报警",
            description :  "报警",
          },
          {
            key :  "zt",
            comment :  "状态",
            description :  "状态",
          },
          {
            key :  "zqsw",
            comment :  "闸前水位",
            description :  "闸前水位",
          },
          {
            key :  "zhsw",
            comment :  "闸后水位",
            description :  "闸后水位",
          },
          {
            key :  "zw",
            comment :  "闸位",
            description :  "闸位",
          },
          {
            key :  "djssgzsj",
            comment :  "电机实时工作数据",
            description :  "电机实时工作数据",
          },
          {
            key :  "gzzt",
            comment :  "工作状态",
            description :  "工作状态",
          },
          {
            key :  "sw",
            comment :  "水位",
            description :  "水位",
          },
          {
            key :  "czlx",
            comment :  "操作类型",
            description :  "操作类型",
          },
          {
            key :  "czsj",
            comment :  "操作时间",
            description :  "操作时间",
          },
        ],
        tableTotal:0,//明细条数
        chooseList:[],//多选数据
        tableData:[
          {ssll:'89.2',ljsl:'20098.5',bj:'未报警',zt:'正常',zqsw:'182',zhsw:'122',zw:'中',djssgzsj:'332',gzzt:'zc',sw:'98',czlx:'自报',czsj:'2023-12-25 09:54:00'},
          {ssll:'29.2',ljsl:'20088',bj:'未报警',zt:'正常',zqsw:'182',zhsw:'112',zw:'中',djssgzsj:'330',gzzt:'zc',sw:'78',czlx:'实时',czsj:'2023-12-24 08:54:00'},
        ],
        tableHeight:500,//表格高度
      }
    },
    watch: {
      
    },
    computed: {
      
    },
    filters: {
      
    },
    created() {
      // this.getList()
    },
    mounted() {
      // 计算table高度
      this.$nextTick(()=>{
        let ele=document.getElementById("table_box_height");
        this.tableHeight=ele.offsetHeight + 80
        window.addEventListener('resize', () => { 
          let ele=document.getElementById("table_box_height");
          this.tableHeight=ele.offsetHeight + 80
          this.$forceUpdate()
        });
        // 计算table高度
      })
    },
    methods: {
      // 获取数据
      getList() {
        this.loading = true
        basicDataApi.cbxxGetList(this.listQuery).then(response => {
          if(response.code == 200){
            response.columnHeaders.forEach((item) => { // 首字母小写
              item.key = item.key.substring(0, 1).toLowerCase() + item.key.substring(1)
            })
            this.firstHeaderList = response.columnHeaders.filter(u => u.browsable && u.isList)
            this.tableData = response.data
            this.tableTotal = response.count
          }else{
            this.firstHeaderList = []
            this.tableData = []
            this.$notify({
              message: response.message,
              type: 'warning',
              duration: 2000
            });
          }
          this.loading = false
        }).catch(()=>{
          this.firstHeaderList = []
          this.tableData = []
          this.loading = false
        })
      },
      // 搜索
      handleFilter() {
        this.listQuery.page = 1
        this.getList()
      },
      // 切换分页
      handleCurrentChange(val) {
        this.tableChooseRow = {}//重置选中项
        this.listQuery.page = val.page
        this.listQuery.limit = val.limit
        this.getList()
      },
      // 行点击
      tableRowClick(row){
        this.tableChooseRow = row
      },
      //多选
      handleSelectionChange(val){
        this.chooseList = val
      },
      handleDelete(row) { // 多行删除
        this.tableData.splice(this.tableData.findIndex(e=>e.serialNumber === row.serialNumber),1)
      },
      // 按钮点击
      onBtnClicked: function(domId) {
        console.log('you click:' + domId)
        if(domId == 'btnAdd'){//新增
          this.FormDialog = true
          return
        }
        if(domId == 'btnEdit'){//编辑
          if(this.chooseList.length != 0){
            this.$message.error('请选择一个设备进行操作！');
            return;
          }
          this.FormDialog = true
          return
        }
        if(domId == 'btnDel'){//删除
          if(this.chooseList.length <= 0){
            this.$message.error('请选择需要删除的设备！');
            return;
          }
          this.$message.success('删除成功');
          return
        }
        
        if(domId == 'btnExport'){//导出
          this.handleDownExcel()
          return
        }
      },
    },
  }

</script>

<style lang="scss" scoped>

</style>
