<template>
  <div class="tab_container">
    <!-- {{ $route.query.row }} -->
    <el-tabs v-model="activeName" @tab-click="handleClick" class="tabs_css">
      <!-- <el-tab-pane label="设备信息" name="sbxx" class="pane_css">
        <div class="tab_content" v-if="isSbxx">
          <sbxx ref="sbxx" :basicInformation="basicInformation" :configurationInformation="configurationInformation" :videoUrl="videoUrl" :rowData="$route.query.row" @upBasicInformation="upBasicInformation" ></sbxx>
        </div>
      </el-tab-pane> -->
      <el-tab-pane label="设备信息" name="sbxx" class="pane_css">
        <div class="tab_content" v-if="isSbxx">
          <sbxx ref="sbxx"   :rowData="JSON.parse(this.$route.query.row)"  ></sbxx>
        </div>
      </el-tab-pane>
      <!-- <el-tab-pane label="基本信息" name="jbxx" class="pane_css">
        <div class="tab_content" v-if="isJbxx">
          <jbxx></jbxx>
        </div>
      </el-tab-pane>
      <el-tab-pane label="基础配置" name="jcpz" class="pane_css">
        <div class="tab_content" v-if="isJcpz">
          <jcpz></jcpz>
        </div>
      </el-tab-pane> -->
      <!-- <el-tab-pane label="控制管理" name="kzgl" class="pane_css"> -->
      <!-- <el-tab-pane label="自报数据" name="kzgl" class="pane_css">
        <div class="tab_content" v-if="isKzgl">
          <kzgl></kzgl>
        </div>
      </el-tab-pane> -->
      <!-- <el-tab-pane label="实时数据" name="sssj" class="pane_css">
        <div class="tab_content" v-if="isSssj">
          <sssj></sssj>
        </div>
      </el-tab-pane>
      <el-tab-pane label="视频展示" name="spzs" class="pane_css">
        <div class="tab_content" v-if="isSpzs">
          <spzs></spzs>
        </div>
      </el-tab-pane>
      <el-tab-pane label="计划列表" name="jhlb" class="pane_css">
        <div class="tab_content" v-if="isJhlb">
          <jhlb></jhlb>
        </div>
      </el-tab-pane>
      <el-tab-pane label="分析图表" name="fxtb" class="pane_css">
        <div class="tab_content" v-if="isFxtb">
          <fxtb></fxtb>
        </div>
      </el-tab-pane> -->
      <el-tab-pane label="报警信息" name="bjxx" class="pane_css">
        <div class="tab_content" v-if="isBjxx">
          <bjxx></bjxx>
        </div>
      </el-tab-pane>
      <el-tab-pane label="历史数据" name="lssj" class="pane_css">
        <div class="tab_content" v-if="isLssj">
          <lssj></lssj>
        </div>
      </el-tab-pane>
      <el-tab-pane label="设备升级" name="sbsj" class="pane_css">
        <div class="tab_content" v-if="isSbsj">
          <sbsj></sbsj>
        </div>
      </el-tab-pane>
    </el-tabs>
  </div>

</template>

<script>
  import * as basicDataApi from '@/api/basicDataApi/basicDataApi'//基础资料接口
  
  import Jbxx from './components/jbxx.vue'//基本信息
  import jcpz from './components/jcpz.vue'//基础配置
  import kzgl from './components/kzgl.vue'//控制管理
  import Sssj from './components/sssj.vue'
  import Spzs from './components/spzs.vue'
  import Jhlb from './components/jhlb.vue'
  import Fxtb from './components/fxtb.vue'
  import lssj from './components/lssj.vue'
  import Sbsj from './components/sbsj.vue'
  import Sbxx from './components/sbxx.vue'
  import Bjxx from './components/bjxx.vue'
  
  export default {
    name: 'hsycjkequipmentdetails',//设备详情
    components: {
      Jbxx,
      jcpz,
      kzgl,
      Sssj,
      Spzs,
      Jhlb,
      Fxtb,
      lssj,
      Sbsj,
      Sbxx,
      Bjxx,
    },
    directives: {
      
    },
    data() {
      return {
        rowData:{},
        videoUrl:"",
        basicInformation:{},//主表信息
        configurationInformation:{},//属性信息
        activeName: 'sbxx',//当前显示页面
        isSbxx:true,//是否显示设备信息
        isJbxx:true,//是否显示基本信息
        isJcpz:false,//是否显示基础配置
        isKzgl:false,//是否显示控制管理
        isSssj:false,//是否显示实时数据
        isSpzs:false,//是否显示视频展示
        isJhlb:false,//是否显示计划列表
        isFxtb:false,//是否显示分析图表
        isBjxx:false,//是否显示报警信息
        isLssj:false,//是否显示历史数据
        isSbsj:false,//是否显示设备升级
      }
    },
    watch: {
      isSbxx(n,o){
        if(!n){
          if (this.$store.state.sbxxTimer) {
            clearInterval(this.$store.state.sbxxtimer);
          }
          if (this.$store.state.setTimeoutId) {
            clearTimeout(this.$store.state.setTimeoutId);
          }
        }
      }
    },
    beforeRouteLeave(to, from, next) {
      if (this.$store.state.sbxxTimer) {
        clearInterval(this.$store.state.sbxxtimer);
      }
      if (this.$store.state.setTimeoutId) {
        clearTimeout(this.$store.state.setTimeoutId);
      }
      next();
   },
    beforeDestroy() {
      if (this.$store.state.sbxxTimer) {
        clearInterval(this.$store.state.sbxxtimer);
      }
      if (this.$store.state.setTimeoutId) {
        clearTimeout(this.$store.state.setTimeoutId);
      }
    },
    computed: {
      // orgsTree() {
      //   return this.$store.state.AllArea;
      // },
    },
    filters: {
      
    },
    created() {
      if(!JSON.parse(this.$route.query.row)){
        this.$message.error("请通过设备数据页面进入")
        return 
      }
      if(this.$route.query.id){//如果是页面跳转
        this.listQuery.TreeIds = []
        this.listQuery.TreeIds.push(this.$route.query.id)
        this.defaultChoose = []
        this.defaultChoose.push(this.$route.query.id)
        
      }
    },
    mounted() {
      // this.getDeviceInformation()
      const urlParams = new URLSearchParams(window.location.search);
      const dataParam = urlParams.get('row');
      const data = JSON.parse(decodeURIComponent(dataParam));
      // console.log(data,789987)
      //  this.rowData = JSON.parse(this.$route.query.row)
      //  console.log(JSON.parse(this.$route.query.row) )
    },
    methods: {
      getDeviceInformation(){
        this.basicInformation={}
        this.configurationInformation={}
        basicDataApi.cpxqGetDeviceInfoAndHistoryData({DeviceId:this.$route.query.row.id,StartTime:new Date(new Date().getTime() - 1000*60*60*24*10),EndTime:new Date()}).then(response => {
          if(response.code == 200){
            // console.log(response,77777777777)
            // return
            let newResult={}
            for (let key in response.result) {
              let newKey=key.charAt(0).toUpperCase() + key.slice(1);
              newResult[newKey]=response.result[key]
            }
            // console.log(newResult.HsRealDataList,789987)
            if(this.$route.query.row.deviceTypeId!="HS_CKYTHZM"){
              let shuiwei=[]
              let liuliang=[]
              if(newResult.HsRealDataList.length>0){
                newResult.HsRealDataList.forEach((item)=>{
                  if(this.$route.query.row.deviceTypeId=='HS_SWJ'){
                    if (item.var_index == "003") {
                    //表示水位
                      shuiwei.push(item);
                    } else if (item.var_index == "004") {
                      //表示流量
                      liuliang.push(item);
                    }
                  }else{
                    if (item.var_index == "001") {
                      //表示水位
                      shuiwei.push(item);
                    } else if (item.var_index == "004") {
                      //表示流量
                      liuliang.push(item);
                    }

                  }
                })
              }
              this.$refs.sbxx.shuiweiData(shuiwei)
              this.$refs.sbxx.liuliangData(liuliang)
            }
            this.$refs.sbxx.realTime(newResult)
            this.basicInformation = newResult
            this.configurationInformation = newResult
              basicDataApi.cpxqGetYSYToken({key: this.basicInformation.AccessKey,secret:this.basicInformation.AccessSecret}).then((res)=>{
                if(res.code == 200){
                  this.videoUrl = "https://open.ys7.com/ezopen/h5/iframe?url=" + this.$route.query.row.accessURL + "&accessToken=" + res.result.data.accessToken
                }else{
                  this.videoUrl=""
                }
              
              })
            
          }else{
            this.$message.error("获取失败")
          }
            
        })
      },
      // 切换tab
      handleClick(e) {
        // console.log(e.name);
        this.isSbxx = false//是否显示设备信息
        this.isJbxx = false//是否显示基本信息
        this.isJcpz = false//是否显示基础配置
        this.isKzgl = false//是否显示控制管理
        this.isSssj = false//是否显示实时数据
        this.isSpzs = false//是否显示视频展示
        this.isJhlb = false//是否显示计划列表
        this.isFxtb = false//是否显示分析图表
        this.isBjxx = false//是否显示报警信息
        this.isLssj = false//是否显示历史数据
        this.isSbsj = false//是否显示设备升级

        if(e.name == "sbxx") {//设备信息
          this.isSbxx = true
          // this.getDeviceInformation()
        }else{
         setTimeout(() => {
          if (this.$store.state.setTimeoutId) {
            clearTimeout(this.$store.state.setTimeoutId);
          }
         }, 500);
        }
        if(e.name == "jbxx") {//基本信息
          this.isJbxx = true
        }
        if(e.name == "jcpz") {//基础配置
          this.isJcpz = true
        }
        if(e.name == "kzgl") {//控制管理
          this.isKzgl = true
        }
        if(e.name == "sssj") {//实时数据
          this.isSssj = true
        }
        if(e.name == "spzs") {//视频展示
          this.isSpzs = true
        }
        if(e.name == "jhlb") {//计划列表
          this.isJhlb = true
        }
        if(e.name == "fxtb") {//分析图表
          this.isFxtb = true
        }
        if(e.name == "bjxx") {//报警信息
          this.isBjxx = true
        }
        if(e.name == "lssj") {//历史数据
          this.isLssj = true
        }
        if(e.name == "sbsj") {//设备升级
          this.isSbsj = true
        }
      },
    },
  }

</script>

<style lang="scss" scoped>
  .tab_container{
    padding: 10px;
    .tabs_css{
      background: #fff;
      padding: 10px;
      .pane_css{
        height: calc(100vh - 45px - 95px);
      }
    }
  }
</style>
